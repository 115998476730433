<template>
  <div>
    <template v-if="is.loading">
      <loader/>
    </template>

    <div v-else v-for="login in entities" :key="login.id" class="userlogin">
      <h2>{{ $t('views.users.logins.title') }}</h2>

      <div class="action action-passwordreset">
        <h4 class="h5">{{ $t('views.users.logins.action.passwordreset.title') }}</h4>

        <div v-if="login.lastPasswordReset" class="action-info">{{ $t('views.users.logins.action.passwordreset.info.title') }} <span v-b-tooltip.hover.bottom="$d(new Date(login.lastPasswordReset), 'long')"><timeago :datetime="login.lastPasswordReset" auto-update/></span></div>
        <b-alert class="action-description" variant="info" show><b-icon icon="circle-info"/> {{ $t('views.users.logins.action.passwordreset.description') }}</b-alert>

        <div>
          <b-button class="action-button" variant="warning" @click="triggerPasswordreset(login)"><b-icon icon="reset"/> {{ $t('views.users.logins.action.passwordreset.button') }}</b-button>
        </div>
      </div>

      <div class="action action-approvalstate">
        <h4 class="h5">{{ $t('views.users.logins.action.approvalstate.title') }}</h4>

        <i18n class="action-info" path="views.users.logins.action.approvalstate.info.base" tag="div">
          <template #state><span>{{ $t(`views.users.logins.action.approvalstate.info.${login.approvalStatus}`) }}</span></template>
        </i18n>
        <b-alert class="action-description" variant="info" show><b-icon icon="circle-info"/> {{ $t(`views.users.logins.action.approvalstate.description.${login.approvalStatus}`) }}</b-alert>

        <div>
          <b-button-group>
            <b-button
              v-for="state in approvalStateConfig.filter(s => s.setFromStates.length > 0)"
              :key="state.key"
              class="action-button"
              :variant="state.variant"
              :disabled="!state.setFromStates.includes(login.approvalStatus)"
              @click="setApprovalState({ login, state })"
            >
              <b-icon v-if="state.icon" :icon="state.icon"/> {{ $t(`views.users.logins.action.approvalstate.button.${state.key}`) }}
            </b-button>
          </b-button-group>
        </div>
      </div>

      <div class="action action-miscfunctions">
        <h4 class="h5">{{ $t('views.users.logins.action.miscfunctions.title') }}</h4>
        <h5 class="h6">{{ $t('views.users.logins.action.miscfunctions.emailconfirmation.title') }}</h5>
        <i18n class="action-info" path="views.users.logins.action.miscfunctions.emailconfirmation.base" tag="div">
          <template #state><span>{{ $t(`views.users.logins.action.miscfunctions.emailconfirmation.${login.emailConfirmed}`) }}</span></template>
        </i18n>
        <div v-if="!login.emailConfirmed">
          <b-button class="action-button" variant="warning" @click="triggerEmailConfirmation(login)"><b-icon icon="check"/> {{ $t('views.users.logins.action.miscfunctions.button.approveemail') }}</b-button>
        </div>
        <h5 class="h6 mt-3">{{ $t('views.users.logins.action.miscfunctions.lockoutdate.title') }}</h5>
        <template v-if="login.lockoutEndDate && new Date(login.lockoutEndDate) > new Date()">
          <i18n class="action-info" path="views.users.logins.action.miscfunctions.lockoutdate.base" tag="div">
            <template #lockoutend><span><timeago :datetime="login.lockoutEndDate" auto-update/></span></template>
          </i18n>
          <div>
            <b-button class="action-button" variant="warning" @click="triggerResetLockout(login)"><b-icon icon="reset"/> {{ $t('views.users.logins.action.miscfunctions.button.resetlockout') }}</b-button>
          </div>
        </template>
        <template v-else>
          {{ $t('views.users.logins.action.miscfunctions.lockoutdate.nolockout') }}
        </template>
      </div>
      <div class="action action-roles" v-if="userRoles">
        <h4 class="h5">{{ $t('views.users.logins.action.miscfunctions.roles.title') }}</h4>
        <control-checkbox
          v-for="role in editableRoles"
          :key="role.id"
          :id="`${role.name}-toggle`"
          class="mb-0"
          :label="$t(`views.users.logins.action.miscfunctions.roles.checkbox.${role.name}`)"
          :value="userRoles.includes(role.value)"
          :disabled="(role.essential && loggedInUser.userId === login.userId && userRoles.includes(role.value)) || (role.minimumEdîtorRole && !ownUserRoles.includes(role.minimumEdîtorRole))"
          switch
          @change="$value=> setRole(role.value, $value)"/>
      </div>
    </div>

    <b-modal
      :id="approvalstateConfirmationModal.id"
      v-model="approvalstateConfirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
    >
      <template #modal-header-close><b-icon icon="close"/></template>
      <template #modal-title>{{ $t('views.users.logins.modal.header.title') }}</template>

      <template v-if="approvalstateConfirmationModal.show">
        <i18n :path="`views.users.logins.modal.body.confirmation.${approvalstateConfirmationModal.item.state.key}`" tag="p">
          <!-- <template #loginId>
            <b-badge variant="info">{{ approvalstateConfirmationModal.item.login.loginId }}</b-badge>
          </template> -->
        </i18n>
      </template>

      <template #modal-footer>
        <template v-if="approvalstateConfirmationModal.show">
          <b-button variant="light" @click="closeApprovalStateConfirmation">{{ $t('views.users.logins.modal.footer.button.abort') }}</b-button>
          <b-button variant="danger" @click="setApprovalState(approvalstateConfirmationModal.item, true)"><b-icon :icon="approvalstateConfirmationModal.item.state.icon"/> {{ $t('views.users.logins.modal.footer.button.confirm') }}</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { USER_APPROVALSTATES, USER_ROLES } from '@/constants'

import MixinEntityBase from '@/components/mixins/EntityBase'
import Loader from '@/components/Loader'
import ControlCheckbox from '@/components/form/ControlCheckbox'

const ENTITY_KEY = 'userlogins'
const APPROVAL_STATE_CONFIG = Object.values(USER_APPROVALSTATES)

export default {
  name: 'Users.Logins',
  mixins: [MixinEntityBase],
  components: {
    Loader,
    ControlCheckbox
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      approvalStateConfig: APPROVAL_STATE_CONFIG,
      approvalstateConfirmationModal: {
        id: 'approvalstateConfirmationModal',
        show: false,
        item: null
      },
      editableRoles: [
        { id: 1, name: USER_ROLES.reseller.toLowerCase(), value: USER_ROLES.reseller, essential: false },
        { id: 2, name: USER_ROLES.admin.toLowerCase(), value: USER_ROLES.admin, essential: true },
        { id: 3, name: USER_ROLES.super.toLowerCase(), value: USER_ROLES.super, essential: true, minimumEdîtorRole: USER_ROLES.super }
      ]
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eCurrent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    },
    entities () {
      return this.$store.getters[`${this.eCurrent.key}/getUnwrapped`]
    },
    loggedInUser () {
      return this.$store.getters['profile/get']
    },
    userRoles () {
      return this.$store.getters[`${this.eCurrent.key}/getUserRoles`]
    },
    ownUserRoles () {
      return this.loggedInUser.roles
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch(`${this.eCurrent.key}/getAll`, { by: this.eParent })
    },
    getUserRoles () {
      this.$store.dispatch(`${this.eCurrent.key}/getAllUserRoles`, { by: this.eParent })
    },
    triggerPasswordreset (login = {}) {
      this.$store.dispatch(`${this.eCurrent.key}/triggerPasswordreset`, { by: this.eParent })
    },
    triggerEmailConfirmation (login = {}) {
      this.$store.dispatch(`${this.eCurrent.key}/triggerEmailConfirmation`, { by: this.eParent })
    },
    triggerResetLockout (login = {}) {
      this.$store.dispatch(`${this.eCurrent.key}/triggerResetLockout`, { by: this.eParent })
    },
    setApprovalState (item = {}, confirmed = false) {
      if (item.state.needsConfirmation && !confirmed) {
        this.openApprovalStateConfirmation(item)
      } else {
        this.$store.dispatch(`${this.eCurrent.key}/setApprovalState`, { by: this.eParent, data: { state: item.state.key } })
        this.closeApprovalStateConfirmation()
      }
    },
    openApprovalStateConfirmation (item) {
      this.approvalstateConfirmationModal.item = item
      this.approvalstateConfirmationModal.show = true
    },
    closeApprovalStateConfirmation () {
      this.approvalstateConfirmationModal.show = false
      this.approvalstateConfirmationModal.item = null
    },
    setRole (roleName, active) {
      if (active) {
        this.$store.dispatch(`${this.eCurrent.key}/addUserRole`, { by: this.eParent, data: { roleName: roleName } })
      } else {
        this.$store.dispatch(`${this.eCurrent.key}/removeUserRole`, { by: this.eParent, data: { roleName: roleName } })
      }
    }
  },
  created () {
    this.getEntities()
    this.getUserRoles()
  }
}
</script>

<style lang="scss">
$userlogin-userlogin-gap: $spacer * 3 !default;
$userlogin-userlogin-inner-gap: $userlogin-userlogin-gap * 0.5 !default;
$userlogin-userlogin-border: $border-width solid $border-color !default;

$userlogin-action-action-gap: $spacer * 2 !default;
$userlogin-action-action-border: $border-width solid $border-color !default;

$userlogin-action-info-gap: $spacer * 0.5 !default;
$userlogin-action-info-font-size: $font-size-sm !default;
$userlogin-action-info-font-weight: inherit !default;
$userlogin-action-info-color: inherit !default;
$userlogin-action-info-time-font-size: inherit !default;
$userlogin-action-info-time-font-weight: $font-weight-bold !default;
$userlogin-action-info-time-color: inherit !default;

$userlogin-action-description-gap: $spacer !default;
$userlogin-action-description-font-size: $font-size-sm !default;

.userlogin {
  .action {
    .action-info {
      margin-bottom: $userlogin-action-info-gap;
      font-size: $userlogin-action-info-font-size;
      font-weight: $userlogin-action-info-font-weight;
      color: $userlogin-action-info-color;

      span,
      time {
        font-size: $userlogin-action-info-time-font-size;
        font-weight: $userlogin-action-info-time-font-weight;
        color: $userlogin-action-info-time-color;
      }
    }

    .action-description {
      display: inline-block;
      margin-bottom: $userlogin-action-description-gap;
      font-size: $userlogin-action-description-font-size;
    }

    .action-button {}

    + .action {
      margin-top: $userlogin-action-action-gap * 0.5;
      padding-top: $userlogin-action-action-gap * 0.5;
      border-top: $userlogin-action-action-border;
    }
  }

  + .userlogin {
    margin-top: $userlogin-userlogin-gap;
    padding-top: $userlogin-userlogin-inner-gap;
    border-top: $userlogin-userlogin-border;
  }
}
</style>
